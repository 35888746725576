<template>
  <div>
    <template v-if="settings.isMobileView">
      <div :class="$style.menu">
        <div>
          <cui-mobile-menu />
        </div>
        <div :class="$style.logoContainer">
          <div @click.prevent="changeCurrentMenu" :class="$style.logo">
            <router-link
              :disabled="stillExam"
              to="/"
              :target="currentRouteIsDetailSchedule && isLive ? '_blank' : ''"
            >
              <!-- <img src="@/assets/Logo-Albayan-Islamic-School.png" class="mr-2" :alt="schoolName" height="50" /> -->
              <img
                :src="institusi ? institusi.logo : '/' + logo"
                class="mr-2"
                :alt="schoolName"
                height="50"
              />
            </router-link>
          </div>
        </div>
        <!-- <div class="ml-auto" :style="{paddingRight: role.includes('admin') ? '1%' : 0}"> -->
        <div>
          <cui-user-menu />
        </div>
      </div>
    </template>
    <template v-else>
      <div :class="$style.menu">
        <div :class="$style.logoContainer">
          <div @click.prevent="changeCurrentMenu" :class="$style.logo">
            <router-link
              :disabled="stillExam"
              to="/"
              :target="currentRouteIsDetailSchedule && isLive ? '_blank' : ''"
            >
              <img
                :src="institusi ? institusi.logo : '/' + logo"
                class="mr-2"
                :alt="schoolName"
                width="50"
              />
            </router-link>
          </div>
          <div :class="$style.lineBorder" />
        </div>
        <div :class="$style.navigation">
          <StudentMenu v-if="role.includes('murid')" />
          <TeacherMenu v-if="role.includes('guruMapel')" />
          <AdminMenu v-if="role.includes('admin')" />
        </div>
        <div :style="{ paddingRight: role.includes('admin') ? '1%' : 0 }">
          <cui-user-menu />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CuiMobileMenu from './MobileMenu'
import CuiUserMenu from './UserMenu'
import StudentMenu from '@/components/app/Topbar/Student'
import TeacherMenu from '@/components/app/Topbar/Teacher'
import AdminMenu from '@/components/app/Topbar/Admin'
import config from '@/config'

export default {
  data() {
    return {
      current: [],
      touchStartPrev: 0,
      touchStartLocked: false,
      logo: '',
      schoolName: '',
    }
  },
  components: {
    CuiMobileMenu,
    CuiUserMenu,
    StudentMenu,
    TeacherMenu,
    AdminMenu,
  },
  created() {
    this.logo = config.school.logo
    // console.log(this.logo)
  },
  mounted() {
    // this.logo = config.school.logo
    this.schoolName = config.school.name
    this.bindMobileSlide()
    this.detectViewPort(true)
    window.addEventListener('resize', this.detectViewPortListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.detectViewPortListener)
  },
  methods: {
    changeCurrentMenu() {
      if (this.stillExam) {
        this.$notification.error({
          message: 'On Exam',
          description: 'Sorry. You are on exam right now.',
        })
      } else if (!this.currentRouteIsDetailSchedule && !this.isLive) {
        if (this.role.includes('guruMapel')) {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Dashboard Teacher'],
          })
        } else if (this.role.includes('murid')) {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Dashboard Student'],
          })
        } else if (this.role.includes('admin')) {
          this.$store.commit('menu/SET_STATE', {
            currentMenu: ['Dashboard Admin'],
          })
        }
      }
    },
    toggleMobileMenu() {
      const value = !this.settings.isMobileMenuOpen
      this.$store.commit('CHANGE_SETTING', { setting: 'isMobileMenuOpen', value })
    },
    detectViewPortListener: function () {
      this.detectViewPort(false)
    },
    setViewPort: function (isMobileView = false, isTabletView = false) {
      this.$store.commit('CHANGE_SETTING', { setting: 'isMobileView', value: isMobileView })
      this.$store.commit('CHANGE_SETTING', { setting: 'isTabletView', value: isTabletView })
    },
    detectViewPort: function (firstLoad = false) {
      const isMobile = this.settings.isMobileView
      const isTablet = this.settings.isTabletView
      const width = window.innerWidth
      const state = {
        next: {
          mobile: width < 768,
          tablet: width < 992,
          desktop: !(width < 768) && !(width < 992),
        },
        prev: {
          mobile: isMobile,
          tablet: isTablet,
          desktop: !(isMobile) && !(isTablet),
        },
      }
      // desktop
      if (state.next.desktop && ((state.next.desktop !== state.prev.desktop) || firstLoad)) {
        this.setViewPort(false, false)
      }
      // tablet & collapse menu
      if (state.next.tablet && !state.next.mobile && ((state.next.tablet !== state.prev.tablet) || firstLoad)) {
        this.setViewPort(false, true)
        this.$store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value: true })
      }
      // mobile
      if (state.next.mobile && ((state.next.mobile !== state.prev.mobile) || firstLoad)) {
        this.setViewPort(true, false)
      }
    },
    bindMobileSlide() {
      // mobile menu touch slide opener
      const unify = e => {
        return e.changedTouches ? e.changedTouches[0] : e
      }
      document.addEventListener(
        'touchstart',
        e => {
          const x = unify(e).clientX
          this.touchStartPrev = x
          this.touchStartLocked = x > 70
        },
        { passive: false },
      )
      document.addEventListener(
        'touchmove',
        e => {
          const x = unify(e).clientX
          const prev = this.touchStartPrev
          if (x - prev > 50 && !this.touchStartLocked) {
            this.toggleMobileMenu()
            this.touchStartLocked = true
          }
        },
        { passive: false },
      )
    },
  },
  computed: {
    settings() {
      return this.$store.state.settings
    },
    isOnExam() {
      return this.$store.state.cbt.isOnExam
    },
    isOnExamState() {
      return JSON.parse(localStorage.isOnExam)
    },
    currentMenu() {
      return this.$store.state.menu.currentMenu
    },
    user() {
      return this.$store.state.user.user
    },
    role() {
      return this.$store.state.user.role
    },
    institusi() {
      return this.$store.state.master.institusi
    },
    isLive() {
      return this.$store.state.live.isLive
    },
    currentRouteIsDetailSchedule() {
      return this.$route.name.match(/Detail Schedule/gi) !== null
    },
    stillExam() {
      if (this.isOnExam || (this.isOnExamState && !this.isOnExam)) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
