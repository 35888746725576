<template>
  <a-menu v-model="currentMenu" mode="horizontal">
    <a-menu-item key="Dashboard Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Dashboard Admin' }">
        <a-icon type="home" />Dashboard
      </router-link>
    </a-menu-item>
    <a-menu-item key="Master Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Master Admin' }">
        <a-icon type="database" />Master Data
      </router-link>
    </a-menu-item>
    <a-menu-item key="Student Candidate Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Student Candidate Admin' }">
        <a-icon type="user" />Student Candidate
      </router-link>
    </a-menu-item>
    <!-- <a-menu-item key="Teacher Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Teacher Admin' }">
        <a-icon type="audit" />Teachers
      </router-link>
    </a-menu-item>
    <a-menu-item key="Class Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Class Admin' }">
        <a-icon type="bank" />Classes
      </router-link>
    </a-menu-item>
    <a-menu-item key="Student Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Student Admin' }">
        <a-icon type="team" />Students
      </router-link>
    </a-menu-item> -->
    <!-- <a-menu-item key="Report Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Report Admin' }">
        <i class="fas fa-balance-scale" /> Report
      </router-link>
    </a-menu-item> -->
    <!-- <a-menu-item key="Extracurricular Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Extracurricular Admin' }">
        <i class="fas fa-users" /> Extracurricular
      </router-link>
    </a-menu-item> -->
    <a-menu-item key="Blocked Account Admin" class="subMenuDetail">
      <router-link :to="{ name: 'Blocked Account Admin' }">
        <i class="fas fa-user-lock" /> Blocked Account
      </router-link>
    </a-menu-item>
  </a-menu>
</template>
<script>
export default {
  created() {
    if (this.$router.history.current.name.split(' ').includes('Master Admin')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Master Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Teacher')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Teacher Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Student')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Student Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Class')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Class Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Report')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Report Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Extracurricular')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Extracurricular Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Student Candidate')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Student Candidate Admin'],
      })
    } else if (this.$router.history.current.name.split(' ').includes('Blocked Account')) {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Blocked Account Admin'],
      })
    } else {
      this.$store.commit('menu/SET_STATE', {
        currentMenu: [this.$router.history.current.name],
      })
    }
  },
  computed: {
    currentMenu: {
      get: function() {
        return this.$store.state.menu.currentMenu
      },
      set: function(value) {
        this.$store.commit('menu/SET_STATE', {
          currentMenu: value,
        })
      },
    },
    role() {
      return this.$store.state.user.role
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
